<template>
  <el-dialog
    class="common-applicants__choose"
    :title="title[type] + '集体报项'"
    :before-close="handleClose"
    :visible.sync="visible"
    width="580px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      class="form-wrap"
      ref="formEdit"
      :hide-required-asterisk="true"
      :model="formData"
      :rules="rules"
      label-width="140px"
    >
      <el-form-item prop="item">
        <template slot="label">
          <span style="color: #F56C6C; margin-right: 4px;">*</span>
          集体项目：
        </template>
        <el-select
          v-model="formData.item"
          value-key="label"
          placeholder="请选择报名项目"
          clearable
          @change="handleItemChange"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in collectiveOptions"
            :key="index"
            :value="item"
          ></el-option>
        </el-select>
        <!-- <el-input
          style="width: 100%;"
          v-model="formData.item"
          placeholder="请填写项目名称"
        /> -->
      </el-form-item>

      <el-form-item
        prop="itemRoutine"
        v-if="canNotEdit || formData.itemRoutine"
      >
        <template slot="label">
          <span style="color: #F56C6C;  margin-right: 4px;">*</span>
          套路名称：
        </template>
        <el-input
          style="width: 100%;"
          v-model="formData.itemRoutine"
          placeholder="请填写套路名称"
        />
      </el-form-item>

      <el-form-item
        label="姓名："
        :prop="'' + (contestant - 1)"
        :rules="rules.contestants"
        v-for="contestant in contestantsLength"
        :key="contestant"
      >
        <template slot="label">
          <span style="color: #F56C6C;  margin-right: 4px;">*</span>
          姓名：
        </template>
        <div style="display: flex; align-items: center;">
          <el-input
            placeholder="点击选择运动员"
            v-model="contestants[contestant - 1]"
            readonly
            style="flex: 1; min-width: 0;"
            @click.native="handleChoose(contestant - 1)"
          >
            <template slot="append">
              <i class="el-icon-user"></i>
            </template>
          </el-input>
          <i
            class="el-icon-delete"
            v-if="contestantsLength > 6"
            @click="addDel(contestant - 1)"
            style="padding: 0 10px;"
            title="删除"
          ></i>
        </div>
      </el-form-item>
    </el-form>

    <div style="text-align: center; margin-bottom: 30px;">
      <el-button type="success" @click="addUser">添加报项人</el-button>
    </div>
    <div class="form-footer">
      <el-button
        type="primary"
        @click="handleConfirm('formEdit')"
        v-if="type === 'add'"
        >保存</el-button
      >
      <el-button type="primary" @click="handleConfirm('formEdit')" v-else
        >修改</el-button
      >
      <el-button @click="handleCancel('formEdit')">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { returnGroupZhById, returnGroupJt } from "@/utils/utils";
import uniqBy from "lodash/uniqBy";

export default {
  name: "collectiveEdit",
  props: {
    collectiveOptions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      visible: false,
      type: "add",
      title: {
        add: "新增",
        edit: "编辑"
      },
      template: {
        contestants: [],
        itemType: "",
        itemRoutine: ""
      },
      formData: {
        contestants: [],
        itemType: "",
        itemRoutine: ""
      },
      rules: {
        contestants: [
          { validator: this.validateContestants, trigger: "change" }
        ],
        itemType: [
          { required: true, message: "请选择项目类型", trigger: "change" }
        ],
        item: [{ required: true, message: "请填写项目名称", trigger: "blur" }],
        itemRoutine: [
          { required: true, message: "请填写套路名称", trigger: "blur" }
        ]
      },
      commonApplicant: {},
      index: -1,
      canNotEdit: false, // 项目名称默认可编辑
      canCrossGroup: false // 是否可以跨组别
    };
  },
  computed: {
    contestants() {
      let arr = [];
      this.formData.contestants.forEach((item, index) => {
        arr[index] = this.nameFormat(item);
      });
      return arr;
    },
    contestantsLength() {
      return this.formData.contestants.length < 6
        ? 6
        : this.formData.contestants.length;
    }
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    show(type, index, data) {
      this.type = type;
      this.index = index;
      if (data) {
        this.formData = JSON.parse(JSON.stringify(data));
      } else {
        this.formData = JSON.parse(JSON.stringify(this.template));
      }

      this.visible = true;
      this.$nextTick(() => {
        this.$refs["formEdit"].clearValidate();
      });
    },
    hide() {
      this.handleClose();
    },
    // 关闭前的操作
    handleClose() {
      this.visible = false;
      this.$refs["formEdit"].clearValidate();
    },
    // 点击选择用户
    handleChoose(index) {
      this.$emit("choose-user", index, this.formData);
    },
    applicantsConfirm(row, index) {
      console.log("row", row);

      this.$set(this.formData.contestants, index, {
        ...row
      });
    },
    validateContestants(rule, value, callback) {
      let end = parseInt(rule.fullField);
      if (this.formData.contestants[end]) {
        if (
          this.formData.contestants[end] &&
          this.formData.contestants[end].name &&
          this.formData.contestants[end].name.length > 0
        ) {
          let flag = true;
          for (let i = 0; i < end; i++) {
            if (
              this.formData.contestants[rule.fullField] &&
              this.formData.contestants[i] &&
              this.formData.contestants[rule.fullField].cardId ===
                this.formData.contestants[i].cardId
            ) {
              flag = false;
            }
          }
          if (flag) {
            callback();
          } else {
            callback(new Error("组内不能有重复的参赛者。"));
          }
        } else {
          callback(new Error("请选择报项人。"));
        }
      } else {
        callback(new Error("请选择报项人。"));
      }
    },

    handleConfirm(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          // 判断有没有跨组别报名
          let canNotSubmit = false;
          if (!this.canCrossGroup) {
            let groupList = this.formData.contestants.map(
              item => item.teamGroup
            );
            console.log("groupList :>> ", groupList);
            let unqRes = uniqBy(groupList);
            console.log("unqRes :>> ", unqRes);
            if (unqRes.length > 1) {
              this.$alert("不允许跨组别报名。", "提示", {
                type: "error"
              });
              canNotSubmit = true;
              return;
            }
            if (canNotSubmit) {
              return 0;
            }
          }
          this.formData.teamGroup = this.formData.contestants[0].teamGroup;

          this.$emit(
            "confirm",
            this.index,
            JSON.parse(JSON.stringify(this.formData))
          );
        } else {
          return false;
        }
      });
    },

    handleCancel() {
      this.visible = false;
    },
    // 添加报项人
    addUser() {
      this.$set(this.formData.contestants, this.contestantsLength, {});
    },
    // 添加报项人
    addDel(index) {
      this.$delete(this.formData.contestants, index);
    },

    handleItemChange(val) {
      if (val === "(竞技项目)集体基本功") {
        this.canNotEdit = false;
      } else {
        this.canNotEdit = true;
      }
      this.formData.itemRoutine = "";
      this.formData.itemType = 0;
    },

    nameFormat(contestants, showGroupName = true) {
      if (contestants && contestants.name && contestants.name.length > 0) {
        return `${contestants.name}(${contestants.cardId.substr(-6, 6)})${
          showGroupName ? contestants.teamGroup : ""
        }`;
      } else {
        return "";
      }
    }
  },
  components: {}
};
</script>

<style scoped lang="scss">
.form-wrap {
  width: 100%;
  max-width: 500px;
  .form-user {
    display: flex;

    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 15px;
    border: 1px solid #dcdfe6;
    label {
      flex: 1;
    }
    .el-icon-user {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #dcdfe6;
    }
  }
}
.form-footer {
  text-align: center;
}
</style>

<style scoped>
.common-applicants__choose >>> .el-dialog {
  width: 580px;
  max-width: 90%;
}
</style>

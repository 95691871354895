<template>
  <el-dialog
    class="common-applicants__choose"
    :title="title[type] + '个人报项'"
    :before-close="handleClose"
    :visible.sync="visible"
    width="580px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      class="form-wrap"
      ref="formEdit"
      :hide-required-asterisk="true"
      :model="formData"
      :rules="rules"
      label-width="140px"
    >
      <el-form-item prop="name">
        <template slot="label">
          <span style="color: #F56C6C; margin-right: 4px;">*</span>
          运动员：
        </template>
        <el-input
          placeholder="点击选择运动员"
          v-model="user"
          readonly
          style="width: 100%; max-width: 400px;"
          @click.native="handleChoose"
          :disabled="type === 'edit'"
        >
          <template slot="append">
            <i class="el-icon-user"></i>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item v-show="user" prop="boxingType">
        <template slot="label">
          <span style="color: #F56C6C; margin-right: 4px;">*</span>
          套路类型：
        </template>
        <el-select
          v-model="formData.boxingType"
          placeholder="请选择"
          style="width: 100%"
          @change="handleTypeChange"
        >
          <el-option
            v-for="(item, index) in typeOptions"
            :key="index"
            :label="item"
            :value="index"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="
          typeof formData.boxingType === 'number' && formData.boxingType === 0
        "
        prop="wushuType"
      >
        <template slot="label">
          <span style="color: #F56C6C; margin-right: 4px;">*</span>
          竞技类型：
        </template>
        <el-select
          v-model="formData.wushuType"
          placeholder="请选择"
          style="width: 100%"
          @change="handleBoxingTypeChange"
        >
          <el-option
            v-for="(item, index) in routinueTypeOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="
          typeof formData.wushuType === 'number' || formData.boxingType === 1
        "
        prop="boxing"
      >
        <template slot="label">
          <span style="color: #F56C6C; margin-right: 4px;">*</span>
          报项名称：
        </template>
        <el-select
          v-model="formData.boxing"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in boxingTradition"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="
          formData.boxing === '各类传统南派拳术' ||
            formData.boxing === '各式传统太极拳'
        "
        prop="quanshuName"
      >
        <template slot="label">
          <span style="color: #F56C6C; margin-right: 4px;">*</span>
          拳术名称：
        </template>
        <el-select
          v-model="formData.quanshuName"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in quanshuList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!-- 如果套路类型是1:传统套路 而且已经填写报项名称 -->
      <el-form-item
        prop="boxingRoutine"
        :rules="rules.boxingRoutine"
        v-if="formData.boxingType === 1 && formData.boxing"
      >
        <template slot="label">
          <span style="color: #F56C6C;  margin-right: 4px;">*</span>
          套路名称：
        </template>
        <el-input
          style="width: 100%;"
          v-model="formData.boxingRoutine"
          placeholder="请补充套路名称"
        />
      </el-form-item>
    </el-form>

    <div class="form-footer">
      <el-button
        type="primary"
        @click="handleAdd('formEdit')"
        v-if="type === 'add'"
        >新增</el-button
      >
      <el-button type="primary" @click="handleUpdate('formEdit')" v-else
        >修改</el-button
      >
      <el-button @click="handleCancel('formEdit')">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { PROJECT_LIST } from "@/constData/projectRelavant.js";

export default {
  props: {
    instrumentTradition: {},
    instrumentManChangArr: {},
    instrumentWomenChangArr: {},
    instrumentNanArr: {},
    instrumentTaiji: {}
  },
  data() {
    return {
      visible: false,
      type: "add",
      title: {
        add: "新增",
        edit: "编辑"
      },
      typeOptions: [],
      routinueTypeOptions: [],
      boxingTradition: [],
      formData: {
        id: "",
        name: "",
        cardId: "",
        birth: "",
        sex: "",
        size: "",
        group: "",
        wushuType: "", // 套路类型
        boxing: "",
        boxingRoutine: "",
        instrument: "",
        instrumentType: "",
        instrumentRoutine: "",
        instrumentRoutine1: "",
        quanshuName: ""
      },
      rules: {
        name: [{ required: true, message: "请选择报项人", trigger: "change" }],
        boxingType: [{ validator: this.validateType, trigger: "change" }],
        boxing: [{ validator: this.validateProject, trigger: "change" }],
        instrument: [{ validator: this.validateProject, trigger: "change" }],
        boxingRoutine: [
          { required: true, message: "请填写套路名称", trigger: "change" }
        ],
        instrumentRoutine: [
          { required: true, message: "请填写套路名称", trigger: "change" }
        ],
        instrumentRoutine1: [
          { required: true, message: "请填写套路名称", trigger: "change" }
        ]
      },
      commonApplicant: {},
      index: -1,
      showAddProject: false, // 显示增加项目
      showCancelProject: false, // 显示删除项目
      showProject2: false, // 第二个项目默认隐藏
      boxingOptions: [],
      instrumentOptions: [],
      quanshuList: [
        {
          value: "洪拳",
          label: "洪拳"
        }
      ]
    };
  },
  computed: {
    list() {
      return this.$store.state.applicants.applicants;
    },
    user() {
      const row = this.formData;
      if (row.name) {
        return `${row.name}(${row.cardId.substr(-6, 6)})-${row.sex}`;
      } else {
        return "";
      }
    }
  },
  created() {},
  mounted() {
    this.typeOptions = PROJECT_LIST.map(item => item.value);
  },
  watch: {
    // "formData.wushuType": {
    //   immediate: true,
    //   handler(val) {
    //     if (typeof val === "number") {
    //       let list = PROJECT_LIST[this.formData.boxingType].options;
    //       console.log("list", list);
    //       let findObj = list?.find(
    //         item => item.routinueType === this.formData.wushuType
    //       );
    //       console.log("findObj :>> ", findObj);
    //       this.boxingTradition = findObj?.options || [];
    //     }
    //   }
    // },
    "formData.boxing": {
      immediate: true,
      handler(val) {
        if (val) {
          this.handleBoxingChange(val);
        }
      }
    }
  },
  methods: {
    show(type, index, data) {
      this.type = type;
      this.index = index;
      if (data) {
        this.formData = JSON.parse(JSON.stringify(data));

        // if (this.formData.instrument) {
        //   this.showAddProject = false; // 显示增加项目
        //   this.showCancelProject = true; // 显示删除项目
        //   this.showProject2 = true; // 第二个项目默认隐藏
        // } else {
        //   this.showAddProject = true; // 显示增加项目
        //   this.showCancelProject = false; // 显示删除项目
        //   this.showProject2 = false; // 第二个项目默认隐藏
        // }
      } else {
        this.formData = {
          id: "",
          name: "",
          cardId: "",
          birth: "",
          sex: "",
          size: "",
          group: "",
          boxing: "",
          wushuType: "",
          boxingRoutine: "",
          instrument: "",
          instrumentType: "",
          instrumentRoutine: ""
        };
        this.showAddProject = true;
        this.showCancelProject = false;
      }

      this.visible = true;
      this.$nextTick(() => {
        this.$refs["formEdit"].clearValidate();
      });
    },
    hide() {
      this.handleClose();
    },
    // 关闭前的操作
    handleClose() {
      this.visible = false;
      this.$refs["formEdit"].clearValidate();
    },
    handleChoose() {
      if (this.type === "add") {
        this.$emit("choose-user");
      }
    },
    applicantsConfirm(row) {
      let group = row.group;

      this.formData = {
        ...this.formData,
        ...row,
        group,
        // 重新选择运动员后清除原有的项目名和套路名
        boxing: "",
        boxingRoutine: "",
        instrument: "",
        instrument1: "",
        instrumentRoutine: "",
        instrumentRoutine1: ""
      };

      this.$nextTick(() => {
        this.$refs["formEdit"].validateField(["name"]);
      });
    },
    validateType(rule, value, callback) {
      if (typeof this.formData.boxingType !== "number") {
        callback(new Error("至少选择一类项目。"));
      } else {
        callback();
      }
    },
    validateProject(rule, value, callback) {
      if (!this.formData.boxing && !this.formData.instrument) {
        callback(new Error("至少选择一个项目。"));
      } else {
        callback();
      }
    },
    handleAdd(form) {
      console.log("this.$refs[form]", this.$refs[form]);
      this.$refs[form].validate(valid => {
        console.log("valid", valid);
        if (valid) {
          this.$emit(
            "confirm",
            this.index,
            JSON.parse(JSON.stringify(this.formData))
          );
        }
      });
    },
    handleUpdate(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.$emit(
            "confirm",
            this.index,
            JSON.parse(JSON.stringify(this.formData))
          );
        }
      });
    },

    handleCancel() {
      this.visible = false;
    },
    // 处理项目类型变更
    handleTypeChange() {
      if (!this.user) {
        this.$alert("请先选择运动员。", "提示", {
          type: "error"
        });
        return false;
      }
      this.formData.boxing = "";
      this.formData.routineName = "";
      this.formData.wushuType = "";

      if (this.formData.boxingType === 1) {
        this.boxingTradition = PROJECT_LIST[this.formData.boxingType].options;
      } else {
        console.log("this.formData.boxingType :>> ", this.formData.boxingType);
        this.routinueTypeOptions = PROJECT_LIST[
          this.formData.boxingType
        ].options?.map(item => item);
        console.log("this.routinueTypeOptions :>> ", this.routinueTypeOptions);
      }
    },
    // 处理套路类型变更
    handleBoxingTypeChange() {
      // 有变更，所以要重置报项名称、套路名称
      this.formData.boxing = "";
      this.formData.boxingRoutine = "";

      let list = PROJECT_LIST[this.formData.boxingType].options;
      console.log("list", list);
      let findObj = list?.find(item => item.value === this.formData.wushuType);
      console.log("findObj :>> ", findObj);
      this.boxingTradition = findObj?.options || [];

      console.log("this.boxingTradition :>> ", this.boxingTradition);
    },

    // 变更报项
    handleBoxingChange(val) {
      let findObj = this.boxingTradition.find(item => item.value === val);
      console.log("findObj :>> ", findObj);
      if (findObj) {
        this.formData.itemType = findObj.itemType;
      }
      this.quanshuList = findObj.options;
    },

    handleInstrumentChange(val) {
      // 如果没有选择拳术项目
      if (!this.formData.boxing) {
        this.$alert("请先选择拳术项目。", "提示", {
          type: "error"
        });
        this.formData.instrument = "";
      }
      // 如果存在项目名称，再去判断要不要填套路名称
      if (this.formData.instrument) {
        const flag = this.instrumentTradition.indexOf(val) === -1;
        if (flag) {
          this.formData.instrumentRoutine = "";
          this.formData.instrumentType = 1;
        } else {
          this.formData.instrumentType = 0;
        }
        this.$refs["formEdit"].validateField([
          "instrument",
          "instrumentRoutine"
        ]);
      }
    },

    handleInstrumentChange1(val) {
      // 如果存在项目名称，再去判断要不要填套路名称
      if (this.formData.instrument1) {
        const flag = this.instrumentTradition.indexOf(val) === -1;
        if (flag) {
          this.formData.instrumentRoutine1 = "";
          this.formData.instrumentType1 = 1;
        } else {
          this.formData.instrumentType1 = 0;
        }
        this.$refs["formEdit"].validateField([
          "instrument",
          "instrumentRoutine"
        ]);
      }
    },
    // 增加项目的按钮
    handleAddProject() {
      if (!this.formData.instrument) {
        this.$alert("请先补充第一个器械类型。", "提示", {
          type: "error"
        });
        return 0;
      }
      this.showProject2 = true;
      this.showCancelProject = true;
      this.showAddProject = false;
    },
    // 删除项目的按钮
    handleCancelProject() {
      if (this.formData.instrument || this.showProject2) {
        this.showProject2 = false;
        this.formData.instrument1 = "";
        this.formData.instrumentRoutine1 = "";
        this.showAddProject = true;
        this.showCancelProject = false;
        return 0;
      }
    },
    showRoutineName(boxing) {
      let findObj = this.boxingTradition.find(
        item => item.label === boxing && item.isCustomRoutineName
      );
      if (findObj) {
        return true;
      }
      return false;
    }
  },
  components: {}
};
</script>

<style scoped lang="scss">
.form-wrap {
  width: 100%;
  max-width: 500px;
  .form-user {
    display: flex;

    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 15px;
    border: 1px solid #dcdfe6;
    label {
      flex: 1;
    }
    .el-icon-user {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #dcdfe6;
    }
  }
}
.form-footer {
  text-align: center;
}
.form-project {
  margin-bottom: 17px;
  text-align: center;
}
</style>

<style scoped>
.common-applicants__choose >>> .el-dialog {
  width: 580px;
  max-width: 90%;
}
</style>
